<template>
  <div class="add-funds-options">
    <NavBack/>
    <div class="fixed-background sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding pt-unset-embedded-in-ios">
      <div class="has-text-centered has-text-weight-semibold page-title">Add funds</div>
      <div class="main has-background-white">
        <div class="is-flex deposit-option is-clickable" @click="toEftAddFundsPage">
          <div class="left-image eft"></div>
          <div class="right-side is-flex is-flex-direction-column is-justify-content-space-between">
            <div class="option-name has-text-blue has-text-weight-semibold">Via bank transfer</div>
            <div class="prompt">Access to ${{availableInstantFunds}} instant funds</div>
          </div>
        </div>
        <div class="is-flex deposit-option is-clickable" @click="toInterac" v-if="hasInteracFeature">
          <div class="left-image interac"></div>
          <div class="right-side is-flex is-flex-direction-column is-justify-content-space-between">
            <div class="option-name has-text-blue has-text-weight-semibold">Via Interac e-Transfer</div>
            <div class="prompt">Within 1 business day</div>
          </div>
        </div>
        <div class="is-flex deposit-option is-clickable" @click="toWiseInstructionsPage">
          <div class="left-image wise"></div>
          <div class="right-side is-flex is-flex-direction-column is-justify-content-space-between">
            <div class="option-name has-text-blue has-text-weight-semibold">USD Funding</div>
            <div class="prompt">Instructions on how to add USD funds to your USD wallet</div>
          </div>
        </div>
      </div>
      <div class="button-back has-text-primary has-text-weight-bold" @click="goBack">Back</div>
    </div>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import walletRoutes from '@utils/mixins/wallet-routes'
import { getWalletDetails } from '@api/wallet'
import { embeddedInIos } from '@utils/common-methods/common'
import { getFeatureToggle } from '@api/common'

export default {
  components: {
    NavBack,
    MenuAndLogo,
  },
  mixins: [walletRoutes],
  data() {
    return {
      availableInstantFunds: 0,
      hasInteracFeature: false,
    }
  },
  created() {
    this.initToggle()
    this.fetchInstantFunds()
  },
  methods: {
    initToggle() {
      getFeatureToggle('interac').then((data) => {
        if (!data.success) return
        this.hasInteracFeature = data.data.enabled
      })
    },
    fetchInstantFunds() {
      getWalletDetails().then((data) => {
        if (!data.success) return
        this.availableInstantFunds = data.data.remainingEftInstantAmount
      })
    },
    goBack() {
      if (embeddedInIos()) return window.webkit.messageHandlers.closeWebview.postMessage(true)
      this.$router.go(-1)
    },
    toInterac() {
      this.$router.push('/wallet/interac')
    },
    toWiseInstructionsPage() {
      this.$router.push('/wallet/wise')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';
$hover-background: #f6f5fc86;

.add-funds-options {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  padding-bottom: 30px;
  .has-padding {
    padding: 18px 25px 0;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      font-size: 16px;
      border-radius: 12px;
      box-shadow: 0px 30px 50px rgba(215, 145, 51, 0.11);
      margin-bottom: 30px;
      padding: 16px 14px 4px;
      .deposit-option {
        border: 1px solid #F0EEF8;
        border-radius: 12px;
        padding: 8px;
        margin-bottom: 12px;
        &:hover {
          background-color: $hover-background;
        }
        .left-image {
          width: 113px;
          height: 96px;
          padding: 0 10px;
          border-radius: 4px;
          &.eft {
            background: #F0EEF8 url('../../../assets/images/wallet/add-funds-eft.svg') center/contain no-repeat;
            background-origin: content-box;
          }
          &.interac {
            background: #F0EEF8 url('../../../assets/images/wallet/add-funds-interac.svg') center/contain no-repeat;
            background-origin: content-box;
          }
          &.wise {
            background: #F0EEF8 url('../../../assets/images/wallet/add-funds-wise.svg') center/contain no-repeat;
            background-origin: content-box;
          }
        }
        .right-side {
          flex: 1;
          padding-left: 12px;
          .prompt {
            font-size: 12px;
            color: #9185AE;
          }
        }
      }
    }
    .button-back {
      font-family: 'Proxima Nova', sans-serif;
      font-size: 16px;
      position: fixed;
      left: 35px;
      bottom: 22px;
      padding: 8px;
    }
  }
}
@media only screen and (min-width: $min-viewport-width) {
  .add-funds-options {
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
    .button-back {
      display: none;
    }
  }
}
</style>
